<template>
  <div class="prime-education-footer">
    <img class="prime-main-menu-logomark" src="../../assets/MyPCP-Health-Education-Logo.svg" />
    <div class="prime-education-footer-text">
      <router-link to="/privacy-policy">Privacy</router-link> |
      <router-link to="/terms-conditions">Terms & Conditions</router-link>
      <br />
      Copyright © 2010-{{ new Date().getFullYear() }} Lumeris. All rights reserved. <br />
      LumerisEngage&trade; is a trademark of Lumeris.
    </div>
  </div>
</template>

<script>
export default {
  name: "EducationPatientFooter"
};
</script>

<style scoped>
.prime-education-footer {
  background: #f8f8fb;
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 12px;
  font-weight: 500;
  color: var(--ion-color-medium-shade);
  line-height: 1.8;
}
.prime-education-footer a {
  color: var(--ion-color-medium-shade);
  text-decoration: none;
}
.prime-education-footer a:hover {
  color: var(--ion-color-primary);
}
.prime-education-footer .prime-education-footer-text {
  display: block;
  text-align: center;
  padding-top: 40px;
}
</style>
