<template>
  <ion-header mode="ios">
    <ion-toolbar mode="ios">
      <ion-title>
        <img class="prime-main-menu-logomark" src="../../assets/MyPCP-Health-Education-Logo.svg" />
      </ion-title>
    </ion-toolbar>
  </ion-header>
</template>

<script>
export default {
  name: "EducationPatientHeader"
};
</script>

<style scoped>
ion-toolbar {
  height: 60px;
  display: flex;
  box-shadow: var(--prime-card-shadow);
}
.header-ios ion-toolbar:last-child {
  --border-width: 0;
}
</style>
