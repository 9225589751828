<template>
  <div @click="handleClick($event)" class="prime-education-container">
    <div class="prime-education-article-title">
      <h1>{{ content.title }}</h1>
    </div>
    <div class="prime-education-article-media" v-html="content.mediaContent" />
    <div class="prime-education-article-head" v-html="content.head" />
    <div class="prime-education-article-body" v-html="content.body" @click="handleClick($event)" />
    <script type="application/javascript" :src="content.javascriptSources" />
  </div>
</template>

<script>
export default {
  name: "EducationLoader",
  props: {
    content: Object
  },
  created() {
    const scriptEl = document.createElement("script");
    scriptEl.setAttribute("type", "text/javascript");
    scriptEl.innerHTML = this.content.inlineJavascript;
    document.body.append(scriptEl);
  },
  methods: {
    handleClick(event) {
      if (this.isProfessional) {
        event.preventDefault();
        return;
      } else {
        const closestAnchor = event.target.closest("a");
        const url = closestAnchor ? new URL(closestAnchor.href) : undefined;

        // get the id from current url, pass as a route param to the article url IF it is on the same domain as us.
        const validArticleUrlForRedirect = url && document.location.host === url.host && !url.hash;

        if (validArticleUrlForRedirect) {
          event.preventDefault();
          url.pathname += "/" + document.location.pathname.split("/")[2];
          document.location = url.href;
        }
      }
    }
  }
};
</script>
<style>
.prime-education-article-title {
  background: #f8f8fb;
  padding: 30px 0;
  display: flex;
  justify-content: center;
}
#app .prime-education-article-title h1 {
  display: block;
  width: 70%;
  padding: 0 20px;
  font-size: 38px;
  line-height: 1.2em;
}
.prime-education-container .HwElement {
  color: var(--ion-text-color);
  font-family: var(--ion-font-family);
  font-size: 16px;
  font-weight: 400;
}
.prime-education-container #HwSxBody,
.prime-education-container .HwElement caption,
.prime-education-container .HwElement dd,
.prime-education-container .HwElement h1,
.prime-education-container .HwElement h2,
.prime-education-container .HwElement h3,
.prime-education-container .HwElement h4,
.prime-education-container .HwElement h5,
.prime-education-container .HwElement li,
.prime-education-container .HwElement td,
.prime-education-container .HwElement th,
.prime-education-container .HwElement tr .prime-education-container .prime-education-container {
  color: var(--ion-text-color);
  font-family: var(--ion-font-family);
}

#app .prime-education-container .HwElement h2 {
  font-size: 28px;
}

#app .prime-education-container .HwElement h3 {
  font-size: 20px;
}

.prime-education-container .HwNavigationSection .HwSectionTitle {
  border-bottom: none;
}

.prime-education-container #HwFooter {
  border-top: none;
}

.prime-education-container a {
  color: var(--ion-color-primary) !important;
}

.prime-education-container .HwContentTitle {
  display: none;
}

@media screen and (max-width: 1000px) {
  #app .prime-education-article-title h1 {
    width: 100%;
  }
}
@media screen and (max-width: 576px) {
  #app .prime-education-article-title h1 {
    font-size: 24px;
  }
}
</style>
