<template>
  <ion-app>
    <ion-content>
      <EducationPatientHeader />
      <div v-if="articleLoading" class="full-height center-center">
        <ion-spinner name="dots" duration="1200" color="black" />
      </div>
      <EducationLoader v-if="!articleLoading" :content="articleContent" />
      <EducationPatientFooter />
    </ion-content>
  </ion-app>
</template>

<script>
import EducationPatientHeader from "@/components/Education/EducationPatientHeader";
import EducationPatientFooter from "@/components/Education/EducationPatientFooter";
import EducationLoader from "@/components/Education/EducationLoader";
import { send as httpSend } from "@/services/Api";

export default {
  name: "ArticleView",
  components: {
    EducationPatientHeader,
    EducationPatientFooter,
    EducationLoader
  },
  data() {
    return {
      articleId: this.$route.params.id,
      articleLoading: false,
      articleContent: {
        body: "",
        head: "",
        title: ""
      }
    };
  },
  mounted() {
    this.getArticle();
  },
  methods: {
    async getArticle() {
      this.articleLoading = true;
      const method = "GET";
      const path = document.config.getArticle.patient + this.articleId;

      try {
        const result = await httpSend({ path, method });
        const article = result.data[0];
        this.articleContent.head = article.headContent;
        this.articleContent.body = article.bodyContent;
        this.articleContent.title = article.title;
        this.articleContent.inlineJavascript = article.inlineJavascript;
        this.articleContent.javascriptSources = article.javascriptSources;
        this.articleContent.mediaContent = article.mediaContent;
        this.articleLoading = false;
      } catch (error) {
        this.$ionic.toastController
          .create({
            header: "Could not retrieve article. Please try again later.",
            message: error,
            duration: 10000,
            position: "top"
          })
          .then(m => m.present());
        khanSolo.log(error);
      }
    }
  }
};
</script>

<style scoped>
ion-app {
  --ion-background-color: #ffffff;
}
</style>
